:root {
  --primary-color: #480183;
  --off-move-color: #efeff4;
  --white-color: #fff;
  --black-50-color: rgba(0, 0, 0, 0.5);
  --black-80-color: rgba(0, 0, 0, 0.8);
  --black-100-color: rgba(0, 0, 0, 1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
