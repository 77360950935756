.devTools {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.25);
  width: 300px;
}
.gameWrapper {
  width: 100%;
  max-width: 1050px;

  display: flex;
  justify-content: space-between;

  padding-block: 2rem;
  user-select: none;

  .descriptionLabels {
    color: var(--black-50-color);
  }

  .leftSection {
    .targetWord {
      margin-bottom: 50px;
      position: relative;
      .descriptionLabels {
        margin-bottom: 10px;
      }
      .mainTargetWord {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 315px;
        height: 60px;
        background-color: var(--off-move-color);
        color: var(--primary-color);
        font-size: 2rem;
        font-weight: bold;

        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

        filter: blur(15px);
      }
      .requireStartTimer {
        position: absolute;
        top: 20px;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        display: flex;
        justify-content: center;
        align-items: center;

        color: var(--black-50-color);
      }

      &.revealed {
        .requireStartTimer {
          display: none;
        }
        .mainTargetWord {
          filter: blur(0px);
        }
      }
    }

    .timerWrapper {
      margin-bottom: 20px;
      .descriptionLabels {
        margin-bottom: 10px;
      }
      .timerContent {
        display: flex;
        .timer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          // width: 100%;
          flex: 1;
          min-width: 315px;
          height: 60px;
          background-color: var(--off-move-color);
          color: var(--primary-color);
          font-size: 2rem;
          font-weight: bold;

          margin-right: 1rem;

          border-radius: 8px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        }
        .actionButton {
          // width: 50px;
          height: 50px;
          flex: 1;

          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;

          cursor: pointer;

          border: 0;
          background-color: var(--off-move-color);
          color: var(--primary-color);

          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

          font-size: 1.1rem;
          font-weight: bolder;
          .icon {
            fill: var(--primary-color);
            margin-right: 1rem;
          }

          &:hover {
            background-color: var(--primary-color);
            color: var(--white-color);
            .icon {
              fill: var(--white-color);
            }
          }

          &[disabled] {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
    }

    .beginnerModeButton {
      cursor: pointer;
      background: transparent;
      border: 0;
      outline: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: var(--black-50-color);
      height: 30px;
      width: 100%;

      padding-block: 2rem;
      margin-block: 1rem;

      border-radius: 12px;
      &:hover{
        background-color: var(--off-move-color);
      }
      .beginnerModeButtonToggleIcon{
        display: block;
        margin-inline: 2rem;
      }

      .icon {
        fill: var(--black-50-color);
        margin-right: 0.5rem;

        &.turnOn {
          fill: var(--black-50-color);
        }

        &.turnOff {
          fill: var(--primary-color);
        }
      }
    }
    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-block: 20px;
      gap: 1rem;

      .actionButton {
        // width: 50px;
        height: 50px;
        flex: 1;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;

        cursor: pointer;

        border: 0;
        background-color: var(--off-move-color);
        color: var(--primary-color);

        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

        font-size: 1.1rem;
        font-weight: bolder;
        .icon {
          fill: var(--primary-color);
          margin-right: 1rem;
        }

        &:hover {
          background-color: var(--primary-color);
          color: var(--white-color);
          .icon {
            fill: var(--white-color);
          }
        }

        &[disabled] {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }
    .hintWords {
      .descriptionLabels {
        margin-bottom: 10px;
      }
      .mainHintWords {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .hintWord {
          // flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          height: 60px;
          width: 100%;

          padding-inline: 40px;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          background-color: var(--off-move-color);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

          text-transform: capitalize;
          color: var(--primary-color);

          font-size: 1.5rem;
          font-weight: bold;

          margin-bottom: 20px;
          .hidden {
            filter: blur(10px);
          }

          &:hover {
            background-color: var(--primary-color);
            color: var(--white-color);
          }
        }
      }
    }

    .discordWrapper{
      width: 100%;
      
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin-block: 20px;
      a{
      }
      a:hover{
        color: var(--primary-color);
        text-decoration: underline;
      }
    }
  }
  .rightSection {
    .bankOfWordsWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .descriptionLabels {
        max-width: 500px;
        padding-inline: 10px;
        text-align: center;
        margin-bottom: 20px;
      }
      .bankOfWords {
        width: 100%;
        // 4 columns
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 15px;

        // center bank word in the middle
        justify-items: center;

        .bankWord {
          display: flex;
          justify-content: center;
          align-items: center;

          height: 35px;
          min-width: 120px;
          border-radius: 8px;
          cursor: default;
          transition: all 0.2s ease-in-out;
          background-color: var(--off-move-color);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

          text-transform: capitalize;
          color: var(--primary-color);

          // margin-bottom: 10px;

          // &:hover {
          //   background-color: var(--primary-color);
          //   color: var(--white-color);
          // }
        }

        &.activateHighlighting {
          .highlighted {
            background-color: #e1d1f1;
            font-weight: bold;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    max-width: 700px;

    .leftSection {
      padding-inline: 2rem;
      .targetWord {
        margin-bottom: 20px;
      }
      .timerWrapper {
        margin-bottom: 20px;
      }
      .actions {
        justify-content: space-around;
        margin-bottom: 2rem;
      }
    }
    .rightSection {
      padding-inline: 2rem;
      .hintWords {
        margin-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;

    .rightSection {
      .hintWords {
        .mainHintWords {
          .hintWord {
            flex: 1;
            padding-inline: 0;
            &:not(:last-of-type) {
              margin-right: 20px;
            }
          }
        }
      }
      .bankOfWordsWrapper {
        .bankOfWords {
          grid-template-columns: repeat(4, 1fr);
          .bankWord {
            width: 100%;
            min-width: auto;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;

    .rightSection {
      margin-top: 2rem;
      .hintWords {
        .mainHintWords {
          flex-direction: column;

          .hintWord {
            padding-block: 5px;
            width: 100%;
            flex: 1;
            padding-inline: 0;
            margin-right: 0 !important;

            &:not(:last-of-type) {
              margin-bottom: 20px;
            }
          }
        }
      }
      .bankOfWordsWrapper {
        .bankOfWords {
          grid-template-columns: repeat(4, 1fr);
          .bankWord {
            width: 100%;
            min-width: auto;
          }
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    flex-direction: column;

    .leftSection {
      
      padding-inline: 1rem;
      .targetWord {
        .mainTargetWord {
          width: 100%;
          min-width: auto;
        }
      }
      .timerWrapper {
        .timer {
          width: 100%;
          min-width: auto;
        }
      }
    }
    
    .rightSection {
      padding-inline: 1rem;
      .bankOfWordsWrapper {
        .bankOfWords {
          grid-template-columns: repeat(4, 1fr);
          .bankWord {
            width: 100%;
            min-width: auto;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
