.logoWrapper {
  // border: 1px solid var(--primary-color);
  font-size: 1.25rem;
  font-weight: bolder;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .primary-color {
    color: var(--primary-color);
  }
  .gray-color {
    color: var(--black-50-color);
  }
  .black-color {
    color: var(--black-100-color);
  }
  .line {
    display: flex;
    margin-block: -4px;
    span {
      display: block;
      height: 100%;
      padding-right: 3px;
    }
  }
}
