.emailPopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  .emailPopup {
    background-color: var(--white-color);
    padding: 1rem;
    width: 350px;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .description {
      padding-block: 1rem;
      p {
        margin-bottom: 10px;
        // font-size: 12px;
        color: var(--black-80-color);
        &:first-of-type {
          margin-bottom: 0;
        }
      }
    }

    .emailInput {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;
      label {
        display: block;
        margin-bottom: 4px;
        padding-left: 4px;
        color: var(--black-80-color);
      }
      input {
        width: 100%;
        height: 40px;
        border: 0;
        border-radius: 8px;
        padding-inline: 1rem;
        font-size: 1rem;
        color: var(--black-80-color);
        background-color: var(--off-move-color);
        &:focus {
          outline: none;
          border: 2px solid var(--primary-color);
        }
      }
    }

    .buttons {
      width: 100%;
      margin-top: 1rem;
      button {
        width: 100%;
        height: 48px;
        border: 0;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: bold;
        color: var(--white-color);
        background-color: var(--primary-color);
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:focus {
          outline: none;
        }
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    @media screen and (max-width: 400px) {
      .emailPopup {
        width: 100%;
        height: 100%;
      }
    }
  }
}
