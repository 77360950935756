.mainLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
