.sharePopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
  .sharePopup {
    position: relative;
    background-color: var(--white-color);
    padding: 1rem;
    width: 350px;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .closeButton {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      border: 0;
      color: var(--black-80-color);
      background-color: transparent;
      .icon {
        width: 100%;
        height: 100%;
      }
    }
    .logo {
      margin-block: 1rem;
    }
    .congratulation {
      h1 {
        color: var(--primary-color);
        margin-block: 1rem;
      }

      p {
        color: var(--gray-color);
        opacity: 0.7;
      }

      .fasterThanPercentage {
        // color: var(--primary-color);
        margin-block: 1rem;
        font-size: 1.4rem;
        opacity: 1;
      }
    }
    .shareSocialMedia {
      margin-top: 1rem;
      p {
        color: var(--gray-color);
        opacity: 0.7;
      }
      .socialMediaIcons {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-block: 1rem;
        .share-icon {
          margin: 0 0.5rem;
          width: 50px;
          height: 50px;

          border: 0;
          background-color: var(--off-move-color);
          padding: 0.5rem;
          border-radius: 8px;

          cursor: pointer;
          transition: all 0.3s ease-in-out;
          color: var(--primary-color);
          .icon {
            width: 100%;
            height: 100%;
          }
          &:hover {
            color: var(--white-color);
            background-color: var(--primary-color);
          }
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    .sharePopup {
      width: 100%;
      height: 100%;
    }
  }
}
