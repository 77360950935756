.mainNav {
  height: 50px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--off-move-color);

  padding-inline: 25px;

  // bottom shadow
  box-shadow: 0 0 10px var(--black-50-color);

  z-index: 100;

  &__leftSection {
    display: flex;
    align-items: center;
    &__logo {
      user-select: none;
      height: 40px;
    }
  }

  &__rightSection {
    display: flex;
    align-items: center;
    color: var(--primary-color);
    &__links {
      display: flex;
      align-items: center;
      a {
        font-size: 1rem;
        padding: 0 25px;
        text-align: center;
        &:hover {
          // underline
          text-decoration: underline;
        }
      }
    }

    &__languages {
      // select list
      select {
        background-color: var(--off-move-color);
        border: none;
        font-size: 1rem;
        padding: 0 25px;
        text-transform: capitalize;
        color: var(--primary-color);
        &:hover {
          // underline
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
    padding: 1rem 0;

    height: auto;

    &__leftSection {
      margin-bottom: 1rem;
    }
  }

  @media screen and (max-width: 400px) {
    &__rightSection {
      padding-block: 0.5rem;
    }
  }
}
